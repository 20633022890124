import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import useGetAllAvailableFlmrs from "../../Hooks/useGetAllAvailableFlmrs";
import useGetRecentAlbums from "../../Hooks/useGetRecentAlbums";


interface Filmer {
  id: string;
  flmr_listing_banner_urls: string;
  flmr_locations: any;
  username: string;
  bodyText: string;
  flmr_media_type: string;
}

function FilmerCardNew() {
  const filmers = useGetAllAvailableFlmrs();
  const albums = useGetRecentAlbums();

  return (
    <SwiperContainer>
      <Swiper
      slidesPerView="auto"
        // slidesPerView={3}
        spaceBetween={20}
        pagination={{ clickable: true }}
        loop={true}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
      >
        {filmers.map((filmer) => (
          <SwiperSlide key={filmer.id}>
            <Link to={`/flmr/${filmer.username}/${filmer.id}/${filmer.flmr_onboarding_continent}/${filmer.flmr_onboarding_country}/${filmer.flmr_onboarding_state}/${filmer.flmr_locations[0]}`} style={{ textDecoration: "none", color: "inherit" }}>
              <ProfileCardWrapper>
                <MediaContainer>
                  <LocationIndicator><p>{filmer.flmr_onboarding_state}</p></LocationIndicator>
                  <MediaComponent mediaUrl={filmer.flmr_listing_banner_urls} />
                </MediaContainer>
                <HRFancy />
                <TextContainer>
                  <h3>{filmer.username}</h3>
                  {/* <h4>{filmer.flmr_media_type}</h4> */}
                  <p style={{ lineHeight: "20px" }}><b>{filmer.flmr_locations.length}</b> SURF SPOTS</p>
                </TextContainer>
              </ProfileCardWrapper>
            </Link>
          </SwiperSlide>
        ))}

      </Swiper>
    </SwiperContainer>
  );
}

const MediaComponent: React.FC<{ mediaUrl: string }> = ({ mediaUrl }) => {
  const [isVideo, setIsVideo] = useState<boolean | null>(null);

  return isVideo ? (
    <StyledVideo
      src={mediaUrl}
      autoPlay
      muted
      loop
      playsInline
      draggable={false}
      onCanPlay={() => setIsVideo(true)}
      onError={() => setIsVideo(false)}
    />
  ) : (
    <StyledImage src={mediaUrl} alt="Profile" onError={() => setIsVideo(true)} />
  );
};

// **Styling**
const SwiperContainer = styled.div`
  width: 100%;
  // overflow: hidden;

  .swiper-slide {
    display: flex;
    background-color: #30363d;
    width: auto !important;
  }
`;

const ProfileCardWrapper = styled.div`
  width: 300px;
  height: 100%;
  background-color: #3c444c;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MediaContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  background: black;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

const HRFancy = styled.hr`
  background: linear-gradient(to right, #f27a54, #a154f2);
  height: 5px;
  width: 100%;
  border: none;
`;

const TextContainer = styled.div`
  text-align: left;
  padding: 1rem;

  h3 {
    font-family: "Righteous", sans-serif;
    font-size: 20px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
  }

  p {
    font-size: 14px;
    color: #ddd;
  }
`;

const LocationIndicator = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  top: 7%;
  margin-left: 1rem;
  border-radius: 10px;
  background-color: red;
  width: auto;
  padding: 0px 1rem;
  background-color: #1C2127;

  p {
   color: white;
   font-size: 10px;
  }
`

export default FilmerCardNew;

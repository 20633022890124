import React from 'react'
import { Link } from 'react-router-dom';
import IconInstagram from '../../Components/Icons/IconInstagram';
import IconFacebook from '../Icons/IconFacebook';
import FLMRLogo from '../../Images/logo/FLMR';

const Footer = () => {
  return (
    <footer className="bg-[#3C444C] w-full px-4">
      <div className="max-w-[960px] mx-auto flex flex-col md:flex-row justify-between pb-4 pt-8">
        <Link to="/" className="mb-4 md:mb-0"><FLMRLogo/></Link>
        <div className="flex flex-col md:flex-row">
          {/* <Link to="/about" className="text-[#CBCFD4] hover:text-white text-sm md:mr-6 mb-4 md:mb-0">ABOUT</Link> */}
          <Link to="/faqs" className="text-[#CBCFD4] hover:text-white text-sm md:mr-6 mb-4 md:mb-0">FAQs</Link>
          <Link to="/terms-of-service" className="text-[#CBCFD4] hover:text-white text-sm md:mr-6 mb-4 md:mb-0">TERMS OF SERVICE</Link>
          <Link to="/faqs" className="text-[#CBCFD4] hover:text-white text-sm md:mr-6 mb-4 md:mb-0">PRIVACY</Link>
          <Link to="/contact" className="text-[#CBCFD4] hover:text-white text-sm">CONTACT</Link>
        </div>
      </div>
      <div className="max-w-[960px] mx-auto flex justify-between items-center py-2 pb-4">
        <p className="text-[#CBCFD4] text-sm">&copy; 2024 FLMR</p>
        <div className="flex"> 
          <a href="https://www.instagram.com/flmr.app/" target="_blank" className="text-[#CBCFD4] hover:text-white mr-4"><IconInstagram /></a>
          <a href="https://www.facebook.com/share/19XEBEut7C/" target="_blank" className="text-[#CBCFD4] hover:text-white"><IconFacebook /></a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;

import React from 'react';
import { useUserAuth } from '../../Context/UserAuthContext';
import { toast } from 'react-toastify';
import { AddToCart } from '../../Services/api/get/AddToCart/AddToCart';

interface ButtonGroupProps {
  imageUrl: string;
  albumInfo: any;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ imageUrl, albumInfo }) => {

  let { user } = useUserAuth();

  // Handle download functionality
  const handleDownload = async () => {
    try {
      const response = await fetch(imageUrl);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
  
      // Force the downloaded file to have a .png extension
      const defaultFilename = 'download';
      const filenameFromUrl = imageUrl.split('/').pop()?.split('?')[0] || defaultFilename;
      const filename = filenameFromUrl.split('.')[0]; // Strip existing extensions
      link.download = `${filename}.png`;
  
      link.click();
  
      // Clean up the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };
  
  // Handle Add to Cart click - write to cloud firestore
  async function handleAddToCartClick() {
    if (!user) {
      toast.error('Please log in to purchase...', {
        theme: 'dark',
      });
      return;
    }
    await AddToCart(user, albumInfo, imageUrl);
  }

  const handleFavorite = () => {
    toast.warning('Favorites coming soon', {
      theme: 'dark',
    });
  }

  return (
    <div className="inline-flex shadow-sm" role="group">
      {albumInfo?.album_media_price > 0 ? 
        <button
          onClick={handleAddToCartClick}
          type="button"
          style={{ fontSize: "10px" }}
          className="px-4 py-2 font-medium text-[#CBCFD4] bg-[#30363dbf] rounded-l-sm hover:bg-[#30363d] focus:outline-none"
        >
          Buy
        </button>
      : 
      <button
      type="button"
      onClick={handleDownload}
      style={{ fontSize: "10px" }}
      className="px-4 py-2 font-medium text-[#CBCFD4] bg-[#30363dbf] rounded-l-sm hover:bg-[#30363d] focus:outline-none"
    >
      Download
    </button>
      }
      
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        <div
          style={{ fontSize: "10px" }}
          className="px-4 py-2 font-medium text-[#CBCFD4] bg-[#30363dbf] border-r border-l border-[#ffffff33] hover:bg-[#30363d] focus:outline-none"
        >
          View
        </div>
      </a>
      <button
        type="button"
        onClick={handleFavorite}
        style={{ fontSize: "10px" }}
        className="px-4 py-2 font-medium text-[#CBCFD4] bg-[#30363dbf] rounded-r-sm hover:bg-[#30363d] focus:outline-none"
      >
        Favorite
      </button>
    </div>
  );
};

export default ButtonGroup;

import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../../Components/SpotSelectModal/SpotSelectModal';
import { useUserAuth } from '../../Context/UserAuthContext';
import { getDatabase } from 'firebase/database';
import { uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { getFirestore, addDoc, collection } from "firebase/firestore";
import useWatermarkImage from '../../Hooks/useWaterMarkImage';
import ToggleButton from '../../Components/ToggleButton/ToggleButton';
import { FileUploader } from 'react-drag-drop-files';
import BlankModal from '../../Components/Modal/ModalBlank';
import IconUpload from '../../Components/Icons/IconUpload';
import IconCheckCircle from '../../Components/Icons/IconCheckCircle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewAlbum() {

  const storage = getStorage();
  const db = getFirestore();
  const { userProfile, user } = useUserAuth();
  const { watermarkedImages, handleFileInputChange } = useWatermarkImage();
  const { continent, country, state, county, surfspot } = useParams();

  const [uploadCounter, setUploadCounter] = useState('0/0');
  const [albumName, setAlbumName] = useState('');
  const [albumCount, setAlbumCount] = useState('');
  const [skipWatermarking, setSkipWatermarking] = useState(false);
  const [sessionDate, setSessionDate] = useState('');
  const [mediaPrice, setMediaPrice] = useState(0);
  const [albumDescription, setAlbumDescription] = useState('');
  const [originalImages, setOriginalImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadList, setUploadList] = useState([]);
  const [freePhotos, setFreePhotos] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const [isUploading, setIsUploading] = useState(Boolean);
  const [completedImageCollection, setCompletedImageCollection] = useState();
  const [watermarkingComplete, setWatermarkingComplete] = useState(Boolean);
  const [uploadSize, setUploadSize] = useState();
  const [uploadStatus, setUploadStatus] = useState('Getting everything ready..');
  const [processingImages, setProcessingImages] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true); // Initially open

  // Here we are setting an array of original images and an array of 
  const [progress, setProgress] = useState(0);
  const handleChooseFiles = async (file) => {
    
    setProgress(0); // Reset progress
    setOriginalImages(file);
    
    // Check for mp4 type, if true - no need to run handleFileInputChange, just return completed
    let skipWatermark = false;

    Array.from(file).forEach(item => {
      // console.log(item.type);
      if (item.type === 'video/mp4') {
        skipWatermark = true;
        setSkipWatermarking(true);
      }
    });

    // Calculate total size
    let totalSizeInBytes = 0;
    for (const individualFile of file) {
      totalSizeInBytes += individualFile.size;
    }
  
    // Convert total size to megabytes
    const totalSizeInMegabytes = (totalSizeInBytes / (1024 * 1024)).toFixed(2);
    setUploadSize(totalSizeInMegabytes);

    // If total size > 1gig deny upload, reset back to default
    if (totalSizeInMegabytes > 512) {
      toast.error("Upload limit is 512mb, please split into multiple albums.", {
        theme: "dark"
      });
      setOriginalImages([]);
      setUploadSize();
      return
    }

    if (skipWatermark) {
      setProcessingImages(false);
      setIsModalOpen(false);
    } else if (!skipWatermark){
      setProcessingImages(true);
      setIsModalOpen(true);
    }

    { !skipWatermark && 
      // This is where watermarking actually happens
      handleFileInputChange(file, (progress) => {
        // This callback will be called with the progress percentage
        const watermarkProgress = (progress).toFixed(2);
        setProgress(watermarkProgress + '%');
        if (progress === 100) {
          setProgress(
            <div className="flex items-center pt-4">
              <IconCheckCircle /> 
              <div className="ml-4">Completed - You can close this window and create your album by clicking "Create Album"</div>
            </div>);
        }
      });
    }
  }

  // Handle toggle button - sell photos or make them free
  const handleToggle = (value) => {
    setFreePhotos(value);
  };

  // Toggle toasts
  useEffect(() => {
    if(freePhotos === true) {
      setMediaPrice(0);
      toast.info("Your albums photos / videos will be free to download.", {
        theme: "dark"
      });
    }
  }, [freePhotos]);

  // Custom meta data to track whos image this is, etc..
  const metadata = {
    customMetadata: {
      owner: `${user.uid}`,
      album_slug: `/albums/${userProfile?.userId}/${surfspot}/${albumName}`
    }
  }

  // Create Album Logic
  async function writeAlbumData() {
    const database = getDatabase();
    
    let completedFiles = 0;

    // Validation: Required Fields
    if (albumName === '' || sessionDate === '' || mediaPrice === '' || originalImages.length <= 0 ) {
      toast.error("Please fill out all required fields", {
        theme: "dark"
      });
      return;
    }
    // Validation: URL is intact and a surf spot is selected
    if (continent === undefined && country === undefined && state === undefined && county === undefined && surfspot === undefined) {
      toast.error("Choose a spot", {
        theme: "dark"
      });
      return;
    }

    setIsUploading(!isUploading);
    // Take all the watermarked images that are returned from the hook which is a Data URL
    // Then convert the data URL into a File type. This allows us to merge the watermarked images into the FileList which is returned from the upload input (when the user selects images or videos).
    let x = watermarkedImages;
    const urlToBlob = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    }
    const watermarkedFiles = [];
    for (let i = 0; i < x.length; i++) {
      const blob = await urlToBlob(x[i]);
      // const file = new File([blob], `${originalImages[i].name.split('.').shift()}_watermarked.png`);
      const file = new File([blob], `${originalImages[i].name.split('.').shift()}_watermarked.png`, { type: "image/png" });
      watermarkedFiles.push(file);
    }

    let completedArray = [...originalImages, ...watermarkedFiles];
    setCompletedImageCollection([...originalImages, ...watermarkedFiles]);
    setWatermarkingComplete(true);

    // Set photo count here - check for mp4 files as they dont get watermarked so they return the true count
    let isMp4Files = false;
    let aCount;
    completedArray.forEach(file => {
      console.log(file.type);
      if (file.type === 'video/mp4') {
        isMp4Files = true;
      }
    });
    if (isMp4Files) {
      aCount = completedArray.length;
      setAlbumCount(completedArray.length)
    } else {
      aCount = completedArray.length / 2;
      setAlbumCount(completedArray.length / 2);
    }

    for (const file of completedArray) {
      const totalFiles = completedArray.length;
      const storageRef = sRef(storage, `albums/${user.uid}/${surfspot}/${albumName}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);
  
      // Listen for upload progress events
      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const updatedUploadList = [...uploadList];
        const uploadItem = updatedUploadList.find((item) => item.name === file.name);
        if (uploadItem) {
          uploadItem.progress = Math.round(progress);
        } else {
          updatedUploadList.push({ name: file.name, progress: Math.round(progress) });
        }
        setUploadList(updatedUploadList);
  
        // Check if the file upload is completed
        if (progress === 100) {
          completedFiles++;
          setUploadCounter(`${completedFiles}/${totalFiles}`);
        }
      });
  
      try {
        await uploadTask;
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    // Get banner image and set it to defaultAlbumBanner
    // We do a check here for price, if album is free we set the defaultAlbumBanner to the original image. If not free, set it to a watermarked image
    const bannerStorageRef = storage;
    let bannerImageUrl;
    
    if (!skipWatermarking) {
    
      if (parseInt(mediaPrice) > 0) {
        bannerImageUrl = await getDownloadURL(sRef(bannerStorageRef, `albums/${user.uid}/${surfspot}/${albumName}/${watermarkedFiles[0].name}`));
      } else if (parseInt(mediaPrice) === 0) {
        bannerImageUrl = await getDownloadURL(sRef(bannerStorageRef, `albums/${user.uid}/${surfspot}/${albumName}/${originalImages[0].name}`));
      };

    } else {
      bannerImageUrl = ''
    }
    
    // Upload to firestore
    await addDoc(collection(db, "albums"), {
      album_continent: continent,
      album_country: country,
      album_county: county ? county : '',
      album_default_banner: bannerImageUrl,
      album_description: albumDescription,
      album_media_count: aCount,
      album_media_price: mediaPrice,
      album_name: albumName,
      album_owner_id: user.uid,
      album_owner_username: userProfile.username,
      album_slug: `/albums/${user.uid}/${surfspot}/${albumName}`,
      album_spot: surfspot,
      album_state: state,
      album_upload_date: Date(),
      session_date: sessionDate,
      session_time: ''
    });
    toast.success("Your album has been uploaded. View on the dashboard or in My Albums", {
      theme: "dark"
    });
    setUploadStatus('Upload Complete. View Album');
  }
  
  const closeModal = () => {
    setProcessingImages(false);
    setIsModalOpen(false);
  };

  const openInitially = !surfspot || !continent || !country || !state;

  return (
    <Wrapper>
      <HeaderWrapper>
        <h1>New Album</h1>
      </HeaderWrapper>
      <HRFancy />
      {/* No albums message */}
      {/* <NoSpotsWrapper>
        <h2>You haven't uploaded any albums yet.</h2>
      </NoSpotsWrapper> */}
      <NewAlbumWrapper className="wrapper w-full px-4 rounded-md">
      <CreateAlbumHeaderWrapper>
              <div>
                <p><b>Upload Album To</b></p>
                <p>{surfspot ? surfspot : "Please select a spot"}</p>
              </div>
              <div>
                <Modal openInitially={openInitially} />
              </div>
            </CreateAlbumHeaderWrapper>
            <h4>Album Name *</h4>
            <input required 
                   onChange={(e) => setAlbumName(e.target.value.trim())}
                   onKeyDown={(e) => {
                    if (e.key === '/' || e.key === '`' || e.key === '\\' || e.key === '%') {
                        e.preventDefault();
                        toast.error("Can't use " + e.key + " in album names", {
                          theme: "dark"
                        });
                      }
                    }}
                   placeholder="Enter your album name" 
                   className="w-full px-3 py-2 border-divider rounded-sm focus:outline-none focus:ring mb-4 mt-2 bg-inputDark" 
            />
            {/* SESSION DATE */}
            <h4>Session Date ( MM/DD/YYYY ) *</h4>
            <input required onChange={(e) => setSessionDate(e.target.value)} placeholder="Enter the date of the session ( MM/DD/YYYY )" className="w-full px-3 py-2 border-divider rounded-sm focus:outline-none focus:ring mb-4 mt-2 bg-inputDark" />
            {/* PICTURE / VIDEO PRICE */}
            <div className="flex justify-between items-center">
              <div>
                <h4>Picture / Video Price *</h4>
              </div>
              <div className="flex items-center">
                <h4 style={{paddingRight: '8px'}}>Set Free Album</h4>
                <ToggleButton onToggle={handleToggle} />
              </div>
            </div>
            { freePhotos ? 
              <input 
                disabled
                placeholder="Your media will be free to download, toggle 'Set Free Album' to enter a price." 
                className="w-full px-3 py-2 border-divider rounded-sm focus:outline-none focus:ring mb-4 mt-2 bg-inputDark" 
                value={defaultValue} /> 
              : 
              <input 
                required 
                type="number"
                id="mediaPriceInput"
                onChange={(e) => setMediaPrice(parseInt(e.target.value, 10))}
                placeholder="Enter $ per picture ( you can change this anytime )" 
                className="w-full px-3 py-2 border-divider rounded-sm focus:outline-none focus:ring mb-4 mt-2 bg-inputDark" 
                value={mediaPrice} /> 
            }
            {/* ALBUM DESCRIPTION */}
            <h4>Album Description</h4>
            <AlbumDescriptionTextArea 
              onChange={(e) => setAlbumDescription(e.target.value)} 
              placeholder="Optional: Enter a description about this album" 
              className="w-full px-3 py-2 border-divider rounded-sm focus:outline-none focus:ring mb-4 mt-2 bg-inputDark" 
            />
            {/* <h4 className="mb-2">Upload Your Pictures / Videos *</h4> */}
            <div>
              { !isUploading ? 
                <FileUploader classes="file-uploader" handleChange={handleChooseFiles} name="file" multiple required children={
                  <FileUploadWrapper>
                    <div className="iconwrapper"><IconUpload /></div>
                    <div className="py-2 text-center">
                      <p>SELECT YOUR PHOTOS/VIDEOS TO UPLOAD</p>
                      <h4>JPG,PNG,MP4</h4>
                    </div>
                    { originalImages.length > 0 ? <h4 style={{color: '#F27A54', paddingTop: '6px'}}>{originalImages.length} Files Selected</h4> : null }
                    <div className="upload_btn">
                      <p>SELECT FILES</p>
                    </div>
                    { uploadSize && 
                      <h2 style={{color: '#F27A54', marginTop: '1rem', fontSize: '16px'}}>{uploadSize} MB</h2>
                    }
                  </FileUploadWrapper> 
                } /> 
                : null }
                {/* When upload is in progress */}
                {isUploading && 
                  <div className="mt-4">
                  <h4 className="mb-2">Upload Status</h4>
                  <p><b>NOTE:</b> Do not navigate away from this page until the upload has finished</p>
                  {uploadList.map((item, index) => (
                    <div key={index}>
                      <p><b>FILE:</b> {item.name}</p>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `${item.progress}%` }}></div>
                      </div>
                    </div>
                  ))}
                  <p className="upload-counter">{uploadCounter}</p>
                </div>
                }
                { processingImages && 
                  <BlankModal headerText={'Processing Your Images..'} isOpen={isModalOpen} onClose={closeModal}>
                    <div>
                      <p>To protect your images from people stealing them, we watermark each image. Your non-watermarked images are still stored and will be sent to the customer when then are purchased.</p>
                      <h4 className="pt-6">Status</h4>
                      <div className="pb-6" style={{color: '#CBCFD4'}}>{progress}</div>
                    </div>
                  </BlankModal>
                }
            </div>
            {/* <hr style={{height: 1,borderColor: '#5E5E5E'}} className="mt-4 mb-2"/> */}
            {isUploading && <UploadAlbumBtn disabled style={{backgroundColor: 'gray', background: 'gray'}} onClick={writeAlbumData}>Upload Album</UploadAlbumBtn>}
            {!isUploading && <UploadAlbumBtn onClick={writeAlbumData}>Upload Album</UploadAlbumBtn>}

      </NewAlbumWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 1rem;
  border-radius: 3px;
  
  p {
    line-height: 20px;
  }

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    padding: 1rem;
    font-size: 24px;
    font-weight: 900;
  }
`

const CreateAlbumHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0rem;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3C444C;
  height: 69px;
`

const HRFancy = styled.hr`
  background: linear-gradient(to right, #F27A54, #A154F2);
  height: 5px;
  border-top: none;
`

const ButtonWrapper = styled.div`
  padding: 1rem;

  button, a {
    width: 100%;
    height: 35px;
    font-size: 10px;
    color: #CBCFD4;
    border-radius: 7px;
    background-color: #30363d;
  }

  button:hover {
    background-color: #1e2227;
    transition: ease all .2s;
  }
`
const UploadAlbumBtn = styled.button`
  margin: 1.5rem 0rem 1.25rem 0rem;
  width: 125px;
  height: 35px;
  font-size: 12px;
  color: white;
  border-radius: 7px;
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
`

const AlbumDescriptionTextArea = styled.textarea`
  display: flex;
  width: 100%;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 14px;
  outline: none;
  color: #CBCFD4;
  background-color: #30363d;

  ::placeholder {
    color: #5d6165;
  }
`

const FileUploadWrapper = styled.div`
  display: flex;
  cursor: pointer;
  /* Custom border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23333' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 7px;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0rem;

  h4 {
    color: #CBCFD4; 
  }
  .iconwrapper {
    width: 3.5rem;
    height: 3.5rem;
    background-color: #30363d;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload_btn {
    background-color: #30363d;
    padding: .5rem 1rem;
    border-radius: 7px;
    margin-top: 1rem;
    p {
      color: #CBCFD4;
    }
  }
  @media screen and (max-width: 525px) { 
    p {
      font-size: 10px;
    }
  }
`

const NewAlbumWrapper = styled.div`
    background-color: #3c444c;
    margin-top: 1rem;
    padding-bottom: .5rem;

  .progress-bar {
    height: 10px;
    background-color: #ccc;
    border-radius: 5px;
    margin-top: 5px;
  }

  .progress {
    height: 100%;
    border-radius: 5px;
    background-color: #4caf50; /* Green color for the progress bar */
  }

  .upload-counter {
    margin-top: 10px;
    font-weight: bold;
  }

.QGPVV {
  flex-direction: column;
  height: 175px;
  border: dashed 2px #30363d;
  justify-content: center;
  min-width: 100%;
}
.gkwNcv {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
}
  top: 80px;
  // This is hacky and we shouldn't do this, but lets just get it done

  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5d6165;
  }

  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5d6165;
  }

  input::-ms-input-placeholder { /* Microsoft Edge */
    color: #5d6165;
  }

  input {
    background-color: #30363d;
    font-size: 14px;
    color: #CBCFD4;
  }

  /* Chrome, Safari, Edge, Opera */
  #mediaPriceInput::-webkit-outer-spin-button,
  #mediaPriceInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .banner {
    background: linear-gradient(90deg, rgb(242, 122, 84) 0%, rgb(161, 84, 242) 186.42%);
  }

  .userPhoto {
    background-color: gray;
    border-radius: 50%;
  }

  .borderCustom {
    border-bottom: 1px solid #30363d;
  }
`

export default NewAlbum
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useUserAuth } from '../../Context/UserAuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AlbumCard from '../AlbumCard/AlbumCard';
import { toast } from 'react-toastify';
import { getStorage, getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import SpotChip from '../SpotChip/SpotChip';
import { Link } from 'react-router-dom';
import PopularSpots from '../PopularSpots/PopularSpots';
import { query, collection, where, getDocs, getFirestore } from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';

function Profile() {
  let { userProfile, user } = useUserAuth();
  const [albums, setAlbums] = useState([]);
  const [userProfileData, setUserProfileData] = useState();
  const { userid, username } = useParams();
  const [avatar, setAvatar] = useState();
  const [loading, setLoading] = useState(true); // Optional loading state
  const [activeTab, setActiveTab] = useState('albums');
  const storage = getStorage();
  const firestoredb = getFirestore();
  const location = useLocation();
  const navigate = useNavigate();

  // Get user's profile image
  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
    const fetchAvatar = async () => {
      try {
        const url = await getDownloadURL(sRef(storage, `user_images/${userid}/avatar/user_avatar.png`));
        if (isMounted) setAvatar(url);
      } catch (error) {
        console.error("Avatar Error:", error);
      }
    };
    fetchAvatar();

    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [storage, userid]);

  // Get user's albums
  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
    const fetchAlbums = async () => {
      try {
        const q = query(collection(firestoredb, "albums"), where("album_owner_id", "==", userid));
        const querySnapshot = await getDocs(q);
        if (isMounted) {
          const usersAlbums = querySnapshot.docs.map((doc) => doc.data());
          setAlbums(usersAlbums);
          setLoading(false);
        }
      } catch (error) {
        console.error("Albums Fetch Error:", error);
        if (isMounted) setLoading(false);
      }
    };
    fetchAlbums();

    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [firestoredb, userid]);

  // Get users profile information
  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
    const fetchUserProfileData = async () => {
      try {
        const q = query(collection(firestoredb, "users"), where("user_id", "==", userid));
        const querySnapshot = await getDocs(q);
        if (isMounted) {
          const userData = querySnapshot.docs.map((doc) => doc.data());
          setUserProfileData(userData[0]);
          setLoading(false);
        }
      } catch (error) {
        console.error("User Profile Fetch Error:", error);
        if (isMounted) setLoading(false);
      }
    };
    fetchUserProfileData();

    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [firestoredb, userid]);

  // TODO
  function handleClick() {
    toast.warning("Feature coming soon...", {
      theme: "dark"
    });
  }

  // Handle the hash so we can link to certain tabs
  useEffect(() => {
    const hash = location.hash.replace("#", ""); // Remove '#' from hash
    if (hash === "albums" || hash === "hireflmr") {
      setActiveTab(hash);
    }
  }, [location.hash]);

  // Handle changing tabs
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`#${tab}`); // Updates the URL without full page reload
  };

  return (
    <ProfilePage className="w-full mt-6 px-6">
      {/* Profile Intro */}
      <ProfileIntro className="shadow-md">
        <div className="banner">
          <ProfileImageWrapper>
            <img src={avatar} alt="Profile" />
          </ProfileImageWrapper>
        </div>

        <div className='px-8' style={{ marginTop: '7rem' }}>
          <h2>{username}</h2>
          <div className="btn-wrapper mt-4 mb-8">
            <button onClick={handleClick} className="btn-message mr-4">Message</button>
            <ChangeLocationBtn onClick={handleClick}>Share profile</ChangeLocationBtn>
          </div>
        </div>
      </ProfileIntro>

      <div className="flex mt-4 overflow-x-auto overflow-y-hidden whitespace-nowrap dark:border-gray-700 bg-[#3c444d]">
        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'albums' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('albums')}
        >
          Albums
        </button>

        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'hireflmr' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('hireflmr')}
        >
          Hire FLMR
        </button>
      </div>

      {/* Conditionally Render Content Based on Active Tab */}
      <div className="mb-4">
        {/* Albums */}
        {activeTab === 'albums' && (
          <Section className="shadow-md mb-8 p-4">
            {loading ? (
              <p>Loading albums...</p>
                ) : (
                  <>
                    {albums.length > 0 ? (
                      <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2">
                        {albums.map((item, index) => (
                          <AlbumCard
                            key={index}
                            album_continent={item.album_continent}
                            album_country={item.album_country}
                            album_county={item.album_county}
                            album_default_banner={item.album_default_banner}
                            album_description={item.album_description}
                            album_media_count={item.album_media_count}
                            album_media_price={item.album_media_price}
                            album_name={item.album_name}
                            album_owner_id={item.album_owner_id}
                            album_owner_username={item.album_owner_username}
                            album_slug={item.album_slug}
                            album_spot={item.album_spot}
                            album_state={item.album_state}
                            album_upload_date={item.album_upload_date}
                            session_date={item.session_date}
                            session_time={item.session_time}
                          />
                        ))}
                      </div>
                    ) : (
                      <NoSpotsWrapper>
                        <h2>{username} hasn't uploaded any albums yet.</h2>
                      </NoSpotsWrapper>
                    )}
                  </>
                )}
              </Section>
            )}

        {/* Hire Settings */}
        <Section className="shadow-md mb-8">
        {activeTab === 'hireflmr' && (
          loading ? (
            <p>Loading hire settings...</p>
          ) : (
            userProfileData?.is_flmr && userProfileData?.flmr_available == true ? (
              <div className="">
                {/* HOURLY RATE */}
                <div className="separator p-4 py-6">
                  <h2 className="text-[#CBCFD4] text-md">Hourly Rate</h2>
                  <p className="text-[#CBCFD4]">${userProfileData.flmr_hourly_rate} / Hour</p>
                </div>
                {/* MEDIA TYPES */}
                <div className="separator p-4 py-6">
                  <h2 className="text-[#CBCFD4] text-md mb-2">Media Type</h2>
                  <div className="flex">
                    {userProfileData.flmr_media_type.map((data, index) => (
                      <div className="media-type" key={index}>
                        <p className="py-1 px-4">{data}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {/* SURF SPOTS */}
                <div className="separator p-4 py-6">
                  <h2 className="text-[#CBCFD4] text-md mb-2">Available to Book Surf Spots</h2>
                  <div className="flex flex-wrap">
                    <PopularSpots isProfileLinks={true} />
                  </div>
                </div>
                {/* BIO */}
                <div className="p-4 py-6">
                  <h2 className="text-[#CBCFD4] text-md mb-2">Listing Bio</h2>
                  <div className="flex flex-wrap">
                    <p>{userProfileData.flmr_about}</p>
                  </div>
                </div>
              </div>
            ) : (
              <p>User is not available for hire.</p>
            )
          )
        )}
        </Section>

      </div>
    </ProfilePage>
  );
}

const ChangeLocationBtn = styled.button`
  width: 125px;
  height: 35px;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
`

const ProfileImageWrapper = styled.div`
  display: flex;
  height: 175px;

  img {
    position: relative;
    object-fit: cover;
    bottom: -105px;
    left: 2rem;
    background-color: #3c444c;
    height: 148px;
    max-width: 148px;
    min-width: 148px;
    min-height: 148px;
    border-radius: 50%;
    padding: 6px;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 2rem; */
  border: 1px solid #3c444c;

  h2 {
    color: #CBCFD4;
  }
`

const ProfileIntro = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #3c444c;
  border-radius: 7px;

  h2 {
    color: #CBCFD4;
  }

  .btn-message {
    font-family: 'Montserrat';
    width: 125px;
    height: 35px;
    display: flex;
    border-radius: 4px;
    border: 1px solid #CBCFD4;
    color: #CBCFD4;
    font-size: 12px;
    align-items: center;
    justify-content: center;
  }

  .btn-wrapper {
    display: flex;
  }
`

const ProfilePage = styled.div`

  // This is hacky and we shouldn't do this, but lets just get it done
  .no-request-wrapper {
    border: 1px solid #3c444c;
  }

  .media-type {
    border: 1px solid #3c444d;
    border-radius: 3px;
    margin-right: 1rem;
  }

  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5d6165;
  }

  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5d6165;
  }

  input::-ms-input-placeholder { /* Microsoft Edge */
    color: #5d6165;
  }

  input {
    font-size: 12px;
    color: #CBCFD4;
  }

  .banner {
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(90deg, rgb(242, 122, 84) 0%, rgb(161, 84, 242) 186.42%);
  }

  .userPhoto {
    background-color: gray;
    border-radius: 50%;
  }

  .borderCustom {
    border-bottom: 1px solid #30363d;
  }
  .separator {
    border-bottom: 1px solid #3c444d;
  }
`

const SectionFooter = styled.div`
  height: 50px;
  background-color: #3c444c;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn-cancel {
    background-color: #30363e;
    font-size: 10px;
    color: #cbcfd4;
    /* Disabled look */
  }

  .btn-cancel:disabled {
    background-color: #20242b;
    color: #8a8e94;
    opacity: 0.6; 
  }

  .btn-save {
    background: linear-gradient(90deg, #F27A54 0%, #A154F2 100%);
    color: white;
    font-size: 10px;
    /* Disabled look */
  }

  .btn-save:disabled {
    background: linear-gradient(90deg, #b35a3c 0%, #7540b3 100%);
    color: #e0e0e0;
    opacity: 0.6;
  }

`

const NoSpotsWrapper = styled.div`
  color: #CBCFD4;
  display: flex;
  padding: 5rem 2rem;
  justify-content: center;
  border-radius: 4px;
`

export default Profile;

import { useEffect, useState } from "react";
import { query, collection, where, getDocs, getFirestore } from "firebase/firestore";

const useGetAllAvailableFlmrs = () => {
  const [filmers, setFilmers] = useState<any[]>([]);
  const firestoredb = getFirestore();

  useEffect(() => {
    const fetchFilmers = async () => {
      try {
        const q = query(collection(firestoredb, "users"), where("flmr_available", "==", true));
        const querySnapshot = await getDocs(q);
        const filmerList = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Ensure `id` is included
          ...doc.data(), // Spread all other document fields dynamically
        }));
        setFilmers(filmerList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFilmers();
  }, []);

  return filmers;
};

export default useGetAllAvailableFlmrs;

import React from 'react'
import styled from 'styled-components'

interface InfoCardProps {
  title: string,
  bodyText: string
}

function InfoCard(props: InfoCardProps) {
  return (
    <InfoCardWrapper>
      <HRFancy />
      <h3>{props.title}</h3>
      <p>{props.bodyText}</p>
    </InfoCardWrapper>
  )
}

const InfoCardWrapper = styled.div`
  border: 1px solid #3C444C;
  border-radius: 5px;
  padding: 25px 20px;
  
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Righteous';
    font-size: 20px;
  }
  p {
    line-height: 20px;
  }
  
`

const HRFancy = styled.hr `
  background: linear-gradient(to right, #F27A54, #A154F2);
  height: 5px;
  max-width: 170px;
  border-radius: 5px;
  border-top: none;
`

export default InfoCard
import { Firestore, doc, getDoc } from "firebase/firestore";

export const getUserData = async (db: Firestore, userId: string) => {
  if (!userId) throw new Error("User ID is required");
  
  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      throw new Error(`User with ID "${userId}" not found`);
    }

    return { id: userSnap.id, ...userSnap.data() };
  } catch (error) {
    console.error(`Failed to fetch user data for ID "${userId}":`, error);
    throw error; // Re-throw for better error handling upstream
  }
};

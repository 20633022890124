import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SpotChip from "../SpotChip/SpotChip";
import { useNavigate, useParams } from "react-router-dom";
import { getUserData } from "../../Services/api/get/GetUserData/GetUserData";
import { getFirestore } from "firebase/firestore";

const PopularSpotsData = [
  {
    spot_name: 'Scripps Pier - La Jolla',
    spot_displayname: 'Scripps Pier - La Jolla, CA',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - South',
    spot_county: ''
  },
  {
    spot_name: 'Torrey Pines - Blacks Beach',
    spot_displayname: 'Torrey Pines - Blacks Beach, CA',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - South',
    spot_county: ''
  },
  {
    spot_name: 'Pacific Beach',
    spot_displayname: 'Pacific Beach, CA',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - South',
    spot_county: ''
  },
  {
    spot_name: 'Ruggles',
    spot_displayname: 'Ruggles, RI',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: 'Misquamicut',
    spot_displayname: 'Misquamicut, RI',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: 'Oceanside',
    spot_displayname: 'Oceanside, CA',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - South',
    spot_county: ''
  },
  {
    spot_name: 'Lighthouse (Point Judith)',
    spot_displayname: 'Point Judith, RI',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: "Bailey's Beach",
    spot_displayname: "Bailey's Beach, RI",
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: '2nd Beach (Sachuest Beach)',
    spot_displayname: '2nd Beach (Sachuest Beach), RI',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: 'Montera State Beach',
    spot_displayname: 'Montera Beach, CA',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - North',
    spot_county: ''
  },
  {
    spot_name: 'Domes',
    spot_displayname: 'Domes, Rincón PR',
    spot_country: 'Puerto Rico',
    spot_continent: 'North America',
    spot_state: 'Rincón Municipio',
    spot_county: ''
  }
];

function PopularSpots({ className, isProfileLinks }: { className?: string; isProfileLinks: boolean }) {
  const navigate = useNavigate();
  const db = getFirestore(); // Initialize Firestore once
  const { continent, country, state, county, username, userid }: any = useParams();
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    if (!userid) return; // Prevent unnecessary calls

    const fetchUser = async () => {
      try {
        const data = await getUserData(db, userid);
        setUserData(data);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUser();
  }, [username]);

  const handleSpotClick = (spot: any) => {
    const path = isProfileLinks
      ? `/flmr/${username}/${userid}/${continent}/${country}/${state}/${county ? `/${county}` : ""}${spot}`
      : `/${spot.spot_continent}/${spot.spot_country}/${spot.spot_state}/${spot.spot_county ? `/${spot.spot_county}` : ""}${spot.spot_name}`;

    navigate(path);
  };

  const spotsToDisplay = isProfileLinks ? userData?.flmr_locations ?? [] : PopularSpotsData;

  return (
    <div className={className}>
      <div className="flex flex-wrap">
        {spotsToDisplay.map((spot: any, index: number) => (
          <SpotChipWrapper className="grid" key={index}>
            <SpotChip
              spotName={isProfileLinks ? spot : spot.spot_displayname}
              onClick={() => handleSpotClick(spot)}
              statusColor="#ff0000"
              className="bg-color shadow-mg mb-2 less-rounded" key={index} />
          </SpotChipWrapper>
        ))}
      </div>
    </div>
  );
}

const SpotChipWrapper = styled.div`
  margin-right: 0.5rem;

  .less-rounded {
    border-radius: 7px;
  }

  .bg-color {
    background-color: #1C2127;
  }

  .bg-color:hover {
    background-color: #1c2127c0;
  }
`;

export default PopularSpots;

import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { useUserAuth } from '../../Context/UserAuthContext';
import {PaymentElement} from '@stripe/react-stripe-js';
 
function CartSummary() {

  const db = getFirestore();
  let { user } = useUserAuth(); 
  const [cartItems, setCartItems] = useState([]);  
  const [cartTotal, setCartTotal] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        if (user && user.uid) {
          const cartItemsRef = collection(db, 'carts', user.uid, 'items');
          const unsubscribe = onSnapshot(cartItemsRef, (querySnapshot) => {
            const itemsData = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setCartItems(itemsData);
          });
          // Return a cleanup function to unsubscribe from the snapshot listener
          return () => unsubscribe();
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCartItems();
  }, [db, user]);

  // Calculate the ORDER TOTAL
  useEffect(() => {
    const calculateTotal = () => {
      const sum = cartItems.reduce((total, item) => {
        const price = parseFloat(item.image_price);
        return total + price;
      }, 0);
      setCartTotal(sum);
      const fee = sum * 0.1; // Calculate 10% of the order total
      setTransactionFee(fee);
    };
  
    calculateTotal();
  }, [cartItems]);

  // get data
  const fetchData = async () => {
    const response = await fetch("/.netlify/functions/test");
    const movies = await response.json();
    console.log(movies);
  }

  // Handle checkout click
  async function handleCheckout() {
    // open modal
    // setModalOpen(true);
    // await fetchData();

    toast.warning("DM on Instagram to purchase", {
      theme: "dark"
    });
  }

  return (
    <Wrapper className='shadow-lg'>
      <div className="section">
        <div>
          <h5>ORDER SUMMARY</h5>
            {cartItems.map((image, index) => (
              <div className="flex justify-between" key={index}>
              <p><span  className="item-counter">1 x </span>{image.id}</p>
              <p>${image.image_price}</p>
              </div>
            ))}
        </div>
      </div>
      <div className="section">
        <h5>DELIVERY METHOD</h5>
        <div className="flex justify-between">
          <p>Instant Download</p>
          <p>FREE</p>
        </div>
      </div>
      <div className="section">
        <h5>TOTAL</h5>
        <div className="flex justify-between">
          <p>Amount</p>
          <p>${cartTotal}</p>
        </div>
        <div className="flex justify-between">
          <p>Tax</p>
          <p>Calculated at Checkout</p>
        </div>
        <div className="flex justify-between">
          <p>Transcation Fee (10%)</p>
          <p>${transactionFee.toFixed(2)}</p>
        </div>
      </div>
      <div className="section no-border">
        <div className="flex justify-between items-center">
          <h5>ORDER TOTAL</h5>
          <p>${(cartTotal + transactionFee).toFixed(2)}</p>
        </div>
      </div>
      <SummaryCheckoutWrapper className="flex flex-col">
        <CouponBtn>ADD COUPON CODE HERE</CouponBtn>
        <CheckoutBtn onClick={handleCheckout}>CHECKOUT</CheckoutBtn>
        {/* <PaymentElement /> */}
      </SummaryCheckoutWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`

  background-color: #3C444C;
  border-radius: 7px;

  h5 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    color: white;
  }

  .section {
    padding: 1.5rem;
    border-bottom: 1px solid #30363D;
  }

  .section .item-counter {
    color: #F27A54;
  }

  .no-border {
    border: none;
  }
`

const SummaryCheckoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

const CouponBtn = styled.button`
  background-color: #30363D;
  height: 50px;
  border-radius: 7px;
  margin-bottom: 1rem;
  color: #CBCFD4;
  font-size: 12px;
`
const CheckoutBtn = styled.button`
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
  height: 50px;
  border-radius: 7px;
  font-size: 12px;
  color: white;
  font-weight: 500;
`

export default CartSummary
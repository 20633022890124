import { useEffect, useState } from "react";
import { collection, getDocs, getFirestore, query } from "firebase/firestore";

const useGetRecentAlbums = () => {
  const [albums, setAlbums] = useState<any[]>([]);
  const firestoredb = getFirestore();

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        console.log("Starting to fetch albums...");
        const albumsRef = collection(firestoredb, "albums");
        
        // Get all albums without sorting
        const q = query(albumsRef);
        const querySnapshot = await getDocs(q);
        console.log("Query snapshot received, size:", querySnapshot.size);
        
        const albumList = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          // Convert date string to Date object for proper sorting
          const uploadDate = new Date(data.album_upload_date);
          console.log("Album date:", {
            id: doc.id,
            rawDate: data.album_upload_date,
            parsedDate: uploadDate.toISOString()
          });
          
          return {
            id: doc.id,
            ...data,
            album_upload_date: uploadDate
          };
        });
        
        // Sort albums by date in descending order (newest first)
        const sortedAlbums = albumList.sort((a, b) => {
          return b.album_upload_date.getTime() - a.album_upload_date.getTime();
        });
        
        // Take only the 10 most recent albums
        const recentAlbums = sortedAlbums.slice(0, 15);
        
        console.log("Final sorted album list:", recentAlbums.map(album => ({
          id: album.id,
          date: album.album_upload_date.toISOString()
        })));
        
        setAlbums(recentAlbums);
      } catch (error) {
        console.error("Error fetching albums:", error);
      }
    };

    fetchAlbums();
  }, []);

  return albums;
};

export default useGetRecentAlbums;

import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import AlbumCardPhotoCount from '../AlbumCardPhotoCount/AlbumCardPhotoCount';
import AlbumCardPhotographer from '../AlbumCardPhotographer/AlbumCardPhotographer';
import styled from 'styled-components';
import useGetRecentAlbums from '../../Hooks/useGetRecentAlbums';

type Props = {
  images: 
    {
      album_owner_username: string;
      album_default_banner: any;
      album_media_count: number; src: string; 
      href?: string, 
      photographer?: string,
      photoCount?: number 
    }[];
};

const Carousel: React.FC<Props> = ({ images }) => {
  const [mediaTypes, setMediaTypes] = useState<{ [key: number]: boolean }>({});
  const albums = useGetRecentAlbums();

  const handleMediaType = (index: number, isVideo: boolean) => {
    setMediaTypes(prev => ({
      ...prev,
      [index]: isVideo
    }));
  };

  return (
    <Wrapper>
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        pagination={{ clickable: true }}
        loop={true}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
      >
        {albums.map((album, index) => (
          <SwiperSlide key={index}>
            <a href={album.album_slug}>
              <div className="image shadow-lg">
                {mediaTypes[index] ? (
                  <StyledVideo
                    src={album.album_default_banner}
                    muted
                    loop
                    playsInline
                    draggable={false}
                    onCanPlay={() => handleMediaType(index, true)}
                    onError={() => handleMediaType(index, false)}
                  />
                ) : (
                  <StyledImage 
                    src={album.album_default_banner} 
                    alt="Album" 
                    onError={() => handleMediaType(index, true)}
                  />
                )}
                <div className="photo-count-wrapper">
                  <AlbumCardPhotoCount mediaCount={album.album_media_count}/>
                </div>
                <div className="photographer-wrapper">
                  <AlbumCardPhotographer photographer={album.album_owner_username}/>
                </div>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;

  .swiper {
    background: transparent;
  }

  .swiper-slide {
    width: 250px !important;
    background: transparent;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%; 
  }

  .image {
    width: 250px;
    height: 250px;
    margin: .5rem;
    border-radius: 7px;
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
    background: #3c444c;
  }

  .image:hover {
    transform: translateY(-5px);
  }

  .photo-count-wrapper {
    div {
      margin-top: 10px;
    }
  }

  .photographer-wrapper {
    div {
      margin-top: 160px;
      padding-right: 20px;
      padding-left: 10px;
    }
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

export default Carousel;